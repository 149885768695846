import React from "react"
import styled from "styled-components"
import "react-responsive-carousel/lib/styles/carousel.min.css"
import "./hero.css"
import { Carousel } from "react-responsive-carousel"
import { GatsbyImage } from "gatsby-plugin-image"

import Button from "./button"

import globals from "./globals"
import { P } from "./styledcomponents"

const HeroContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  min-height: max(40vw, 300px);
  width: 100%;
  height: 100%;
  padding: 0;
`

const HeroImageContainer = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  height: 100%;
  z-index: 0;
`

const HeroImage = styled(GatsbyImage)`
  object-fit: cover;
  height: 100%;
  width: 100%;
  transition: 0.3s ease;
`

const HeroTextContainer = styled.div`
  max-width: 500px;
  padding: 1em;
  border-radius: 8px;
  position: relative;
  margin: 3em 0 3em 10%;
  background-color: #00000080;
  backdrop-filter: blur(3px);
  @media (max-width: ${globals.media.mobile}) {
    margin-left: auto;
    margin-right: auto;
  }
`

const Header1 = styled.h1`
  font-size: 1.2em;
  text-align: left;
  margin-bottom: 0.5em;
  color: ${globals.color.main};
  font-weight: bold;
`

const Header2 = styled.h2`
  font-size: 1.2em;
  text-align: left;
  margin-bottom: 0.5em;
  color: ${globals.color.main};
  font-weight: bold;
`

const H1 = styled.h1`
  font-size: 1.6em;
  text-align: left;
  margin-bottom: 0.5em;
  color: ${globals.color.main};
  font-weight: bold;
  @media (max-width: ${globals.media.tablet}) {
    font-size: 1em;
  }
`

const HeroHeadline = styled.p`
  font-size: 2.2em;
  text-align: left;
  color: ${(props) => props.color};
  margin: 0;
  font-weight: bold;
`

const Hr = styled.hr`
  border: solid ${globals.color.main};
  width: 4em;
  margin-left: 0;
  @media (max-width: ${globals.media.tablet}) {
    width: 2.5em;
    2px solid #F17915
  }
`

function Slide(props) {
  return (
    <HeroContainer>
      <HeroImageContainer>
        <HeroImage image={props.img.childImageSharp.gatsbyImageData} />
      </HeroImageContainer>
      <HeroTextContainer>
        {props.id === 1 ? (
          <Header1 color={props.color}>{props.headline[0]}</Header1>
        ) : (
          <Header2 color={props.color}>{props.headline[0]}</Header2>
        )}
        <HeroHeadline color={props.color}>{props.headline[1]}</HeroHeadline>
        <Hr />
        <P color={props.color}>{props.text[0]}</P>
        <P color={props.color}>{props.text[1]}</P>
        <Button
          buttoncolor={props.buttoncolor}
          color={props.color}
          to={"/stundenplan"}
          left
        >
          {props.cta ? props.cta : "Kostenlose Probestunde buchen"}
          {console.log(props)}
        </Button>
      </HeroTextContainer>
    </HeroContainer>
  )
}

function Picture(props) {
  return (
    <HeroContainer>
      <HeroImageContainer>
        <HeroImage
          image={props.img.childImageSharp.gatsbyImageData}
          alt={props.imgAlt}
        />
      </HeroImageContainer>
    </HeroContainer>
  )
}

function Hero(props) {
  return (
    <div>
      <Carousel
        className="hero"
        showThumbs={true}
        autoPlay={true}
        infiniteLoop={true}
        interval="8000"
        showStatus={true}
        preventMovementUntilSwipeScrollTolerance={true}
        swipeScrollTolerance={50}
        emulateTouch={false}
        swipeable={true}
      >
        {props.values.map(({ id, ...otherProps }) => (
          <Slide
            key={id}
            id={id}
            {...otherProps}
            color={props.color}
            pathname={props.pathname}
            cta={props.cta}
          />
        ))}
      </Carousel>
    </div>
  )
}

export function HeroSimple(props) {
  return (
    <HeroContainer>
      <Carousel
        className="hero"
        showThumbs={true}
        autoPlay={true}
        infiniteLoop={true}
        interval="8000"
        showStatus={true}
        pathname={props.pathname}
        preventMovementUntilSwipeScrollTolerance={true}
        swipeScrollTolerance={50}
        emulateTouch={false}
        swipeable={true}

      >
        {props.values.map(({ id, ...otherProps }) => (
          <Picture key={id} {...otherProps} color={props.color} />
        ))}
      </Carousel>
      <HeroTextContainer style={{ position: "absolute", top: 0, pointerEvents: "none" }}>
        <H1 color={props.color}>{props.title}</H1>
        <Hr />
      </HeroTextContainer>
    </HeroContainer>
  )
}

export function HeroStatic(props) {
  return (
    <HeroContainer>
      <Picture key={props.id} {...props} />
      <HeroTextContainer style={{ position: "absolute", top: 0 }}>
        <H1 color={props.color}>{props.title}</H1>
        <Hr />
      </HeroTextContainer>
    </HeroContainer>
  )
}

export default Hero
