import React, { useState } from "react"
import { Link } from "gatsby"
import styled from "styled-components"

import logo from "../images/TLA-Logo.svg"
import Button from "../components/button"
import globals from "./globals"

const Header = styled.header`
  display: flex;
  background-color: ${globals.color.darkShades};
  align-items: center;
`

const Logo = styled(Link)`
  margin-left: 10%;
  margin-top: 0.3em;
  margin-bottom: 0.3em;
  @media (max-width: ${globals.media.mobile}) {
    margin-left: 1em;
  }
`

const TextBox = styled.div`
  margin-top: 0.8em;
  margin-left: 1em;
  text-align: left;
`

const TitleText = styled(Link)`
  font-size: 1.5em;
  color: ${globals.color.lightShades};
  white-space: nowrap;
  text-align: left;
  @media (max-width: ${globals.media.mobile}) {
    font-size: 1em;
  }
`

const TitleDescription = styled.p`
  color: ${globals.color.lightAccent};
  font-size: 1em;
  text-align: left;
  margin: 0.2em;
  @media (max-width: ${globals.media.mobile}) {
    font-size: 0.6em;
  }
`

const BurgerButton = styled.button`
  display: flex;
  background-color: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
  margin-left: auto;
  margin-right: 2em;
  text-decoration: none;
  border-style: none;
  :active {
    outline: none;
    border: none;
  }
  :focus {
    outline: none !important;
  }
  @media (max-width: ${globals.media.mobile}) {
    margin-right: 0.3em;
    width: 4em;
  }
  @media (min-width: ${globals.media.flexBox2}) {
    display: none;
  }
`
const Line = styled.path`
  fill: none;
  stroke: white;
  stroke-width: 6;
  transition: stroke-dasharray 600ms cubic-bezier(0.4, 0, 0.2, 1),
    stroke-dashoffset 600ms cubic-bezier(0.4, 0, 0.2, 1);
`

const Line1 = styled(Line)`
  stroke-dasharray: 60 207;
  stroke-width: 6;
  ${(props) =>
    props.open &&
    `stroke-dasharray: 90 207;
    stroke-dashoffset: -134;
    stroke-width: 6;`}
`

const Line2 = styled(Line)`
  stroke-dasharray: 60 60;
  stroke-width: 6;
  ${(props) =>
    props.open &&
    `stroke-dasharray: 1 60;
  stroke-dashoffset: -30;
  stroke-width: 6;`}
`

const Line3 = styled(Line)`
  stroke-dasharray: 60 207;
  stroke-width: 6;
  ${(props) =>
    props.open &&
    `stroke-dasharray: 90 207;
    stroke-dashoffset: -134;
    stroke-width: 6;`}
`

const Nav = styled.nav`
  display: flex;
  margin-right: 2em;
  background-color: ${globals.color.darkShades};
  z-index: 999;
  align-items: center;
  margin-left: auto;

  @media (max-width: ${globals.media.flexBox2}) {
    display: block;
    ${(props) => !props.showMenu && "display: none"};
    position: absolute;
    top: 5em;
    right: 0em;
    padding: 1em;
    margin-right: 0;
  }
`

const Ul = styled.ul`
  display: block;
  list-style: none;
  margin-right: 5em;
  @media (max-width: ${globals.media.flexBox2}) {
    text-align: right;
    margin-right: 0;
  }
`

const Li = styled.li`
  font-size: 1em;
  margin: 0.5rem;
  text-transform: capitalize;
  display: block;
  @media (min-width: ${globals.media.flexBox2}) {
    display: inline-block;
  }
`

const Item = styled(Link)`
  color: white;
  &:hover {
    color: ${globals.color.main};
  }
`

const MemberLink = styled(Item)`
  margin: 0.5rem;
  font-size: 0.8em;
`

function MainMenu(props) {
  const mainMenuItems = [
    {
      text: "Kampfsport",
      linkTo: "/kampfsport-in-muenchen/",
      id: 1,
    },
    {
      text: "Yoga",
      linkTo: "/yoga-muenchen/",
      id: 2,
    },
    {
      text: "Stundenplan",
      linkTo: "/stundenplan/",
      id: 3,
    },
  ]

  return (
    <Nav showMenu={props.showMenu} role="navigation">
      <Ul>
        {mainMenuItems.map(({ id, linkTo, text }) => (
          <Li key={id}>
            <Item to={linkTo} onClick={props.closeMenu}>
              {text}
            </Item>
          </Li>
        ))}
      </Ul>
      <div>
        <Button
          buttoncolor="#f17915"
          color="#ffffff"
          to="/stundenplan"
          customClickEvent={props.closeMenu}
        >
          Kostenlose Probestunde buchen
        </Button>
      </div>
    </Nav>
  )
}

function Burger({ show, click, open }) {
  return (
    <BurgerButton show={show} onClick={click} aria-label="Main Menu">
      <svg width="70" height="70" viewBox="0 0 100 100">
        <Line1
          open={open}
          d="M 20,29.000046 H 80.000231 C 80.000231,29.000046 94.498839,28.817352 94.532987,66.711331 94.543142,77.980673 90.966081,81.670246 85.259173,81.668997 79.552261,81.667751 75.000211,74.999942 75.000211,74.999942 L 25.000021,25.000058"
        />
        <Line2 open={open} d="M 20,50 H 80" />
        <Line3
          open={open}
          d="M 20,70.999954 H 80.000231 C 80.000231,70.999954 94.498839,71.182648 94.532987,33.288669 94.543142,22.019327 90.966081,18.329754 85.259173,18.331003 79.552261,18.332249 75.000211,25.000058 75.000211,25.000058 L 25.000021,74.999942"
        />
      </svg>
    </BurgerButton>
  )
}

function NavBar(props) {
  const [burgerOpen, setBurgerOpen] = useState(0)

  function toggleOpen() {
    console.log(!burgerOpen)
    setBurgerOpen(!burgerOpen)
  }

  function closeMenu() {
    setBurgerOpen(false)
    console.log("closing")
  }

  return (
    <Header>
      <Logo className="logo" to="/" rel="home">
        <img
          width="65"
          height="65"
          src={logo}
          alt="Das Team Laurien &amp; Alex - BJJ Yoga Selbstvertidigung Logo"
        />
      </Logo>

      <TextBox>
        <TitleText to="/" rel="home">
          Team Laurien & Alex
        </TitleText>
        <TitleDescription>BJJ • Selbstverteidigung • Yoga</TitleDescription>
      </TextBox>
      <Burger show={true} open={burgerOpen} click={toggleOpen}></Burger>
      <MainMenu
        showMenu={burgerOpen}
        pathname={props.pathname}
        closeMenu={closeMenu}
      />
    </Header>
  )
}

export default NavBar
