/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import Helmet from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"

function SEO({ description, lang, meta, title, article, node }) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          buildTime(formatString: "YYYY-MM-DD")
          siteMetadata {
            siteUrl
            title
            description
            author
          }
        }
      }
    `
  )

  const metaDescription = description || site.siteMetadata.description
  const siteUrl = site.siteMetadata.siteUrl
  const author = site.siteMetadata.author
  const buildTime = site.buildTime

  // schema.org in JSONLD format
  // https://developers.google.com/search/docs/guides/intro-structured-data
  // You can fill out the 'author', 'creator' with more data or another type (e.g. 'Organization')

  const schemaOrgLocalBusiness = {
    "@context": "https://schema.org",
    "@type": "SportsClub",
    name: "Team Laurien & Alex",
    image:
      "https://www.team-laurien-alex.de/static/TLA-Logo-79a7fc6a63b324eb2ef61b95932900c2.svg",
    "@id": "https://www.team-laurien-alex.de",
    url: "https://www.team-laurien-alex.de",
    telephone: "+4915771580800",
    priceRange: "0€ - 120€",
    address: {
      "@type": "PostalAddress",
      streetAddress: "Lerchenstr. 14, Haus 3",
      addressLocality: "München",
      postalCode: "80995",
      addressCountry: "DE",
    },
    geo: {
      "@type": "GeoCoordinates",
      latitude: 48.2101209,
      longitude: 11.5284874,
    },
    sameAs: [
      "https://www.instagram.com/teamlaurienalex/",
      "https://www.youtube.com/channel/UCNRyHyAaVAT-m1X0ApuzkIg",
      "https://www.facebook.com/teamlaurienalex/",
      "https://www.team-laurien-alex.de",
    ],
    department: {
      "@type": "HealthClub",
      name: "Team Laurien & Alex",
      image:
        "https://www.team-laurien-alex.de/static/TLA-Logo-79a7fc6a63b324eb2ef61b95932900c2.svg",
      telephone: "+4915771580800",
      priceRange: "0€ - 120€",
      address: {
        "@type": "PostalAddress",
        streetAddress: "Lerchenstr. 14, Haus 3",
        addressLocality: "München",
        postalCode: "80995",
        addressCountry: "DE",
      },
    },
  }

  const schemaOrgWebPage = {
    "@context": "http://schema.org",
    "@type": "WebPage",
    url: siteUrl,
    headline: title,
    inLanguage: lang,
    mainEntityOfPage: siteUrl,
    description: metaDescription,
    name: title,
    author: {
      "@type": "Person",
      name: author,
    },
    copyrightHolder: {
      "@type": "Person",
      name: author,
    },
    copyrightYear: "2019",
    creator: {
      "@type": "Person",
      name: author,
    },
    publisher: {
      "@type": "Person",
      name: author,
    },
    datePublished: "2019-01-18T10:30:00+01:00",
    dateModified: buildTime,
    image: {
      "@type": "ImageObject",
      //url: `${siteUrl}${defaultBanner}`,
    },
  }

  // Initial breadcrumb list

  const itemListElement = [
    {
      "@type": "ListItem",
      item: {
        "@id": siteUrl,
        name: "Homepage",
      },
      position: 1,
    },
  ]

  let schemaArticle = null

  if (article) {
    schemaArticle = {
      "@context": "http://schema.org",
      "@type": "Article",
      author: {
        "@type": "Person",
        name: author,
      },
      copyrightHolder: {
        "@type": "Person",
        name: author,
      },
      copyrightYear: "2019",
      creator: {
        "@type": "Person",
        name: author,
      },
      publisher: {
        "@type": "Organization",
        name: author,
        logo: {
          "@type": "ImageObject",
          //url: `${siteUrl}${defaultBanner}`,
        },
      },
      datePublished: node.first_publication_date,
      dateModified: node.last_publication_date,
      description: description,
      headline: title,
      inLanguage: lang,
      url: siteUrl,
      name: title,
      image: {
        "@type": "ImageObject",
        //url: image,
      },
      mainEntityOfPage: siteUrl,
    }
    // Push current blogpost into breadcrumb list
    itemListElement.push({
      "@type": "ListItem",
      item: {
        "@id": siteUrl,
        name: title,
      },
      position: 2,
    })
  }

  const breadcrumb = {
    "@context": "http://schema.org",
    "@type": "BreadcrumbList",
    description: "Breadcrumbs list",
    name: "Breadcrumbs",
    itemListElement,
  }

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate={`%s`}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: site.siteMetadata.author,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
      ].concat(meta)}
    >
      {!article && (
        <script type="application/ld+json">
          {JSON.stringify(schemaOrgWebPage)}
        </script>
      )}
      {!article && (
        <script type="application/ld+json">
          {JSON.stringify(schemaOrgLocalBusiness)}
        </script>
      )}

      {article && (
        <script type="application/ld+json">
          {JSON.stringify(schemaArticle)}
        </script>
      )}
      <script type="application/ld+json">{JSON.stringify(breadcrumb)}</script>
    </Helmet>
  )
}

SEO.defaultProps = {
  lang: `de`,
  meta: [],
  description: ``,
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
}

export default SEO
