/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import Link from "gatsby-link"
import Helmet from "react-helmet"

import Header from "./header"
import Announcement from "./announcment"
import ScrollToTop from "./scrollTo"
import Footer from "./footer"

import CookieConsent from "react-cookie-consent"
import { P } from "./styledcomponents"

import "./layout.scss"

const Layout = ({ pathname, children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)
  return (
    <>
      <Helmet>
        <link rel="preconnect" href="https://fonts.gstatic.com" />
        <link
          rel="preload"
          href="https://fonts.gstatic.com/s/chivo/v12/va9I4kzIxd1KFrBoQeM.woff2"
          as="font"
          crossOrigin="anonymous"
          type="font/woff2"
        />
        <link
          rel="preload"
          href="https://fonts.gstatic.com/s/chivo/v12/va9I4kzIxd1KFrBmQeNHkQ.woff2"
          as="font"
          crossOrigin="anonymous"
          type="font/woff2"
        />
      </Helmet>
      <Header pathname={pathname} siteTitle={data.site.siteMetadata.title} />
      <div
        style={{
          margin: `0 auto`,
          padding: `0`,
          paddingTop: 0,
        }}
      >
        <main>{children}</main>
        <ScrollToTop />
        <Footer pathname={pathname} />
      </div>
      <CookieConsent
        location="bottom"
        buttonText="Zustimmen"
        cookieName="gatsby-gdpr-google-analytics"
        enableDeclineButton
        declineButtonText="Ablehnen"
        style={{ width: "100vw" }}
      >
        <P>
          Diese Website verwendet Cookies – nähere Informationen dazu und zu
          Ihren Rechten als Benutzer finden Sie in unserer{" "}
          <Link to="/datenschutzerklaerung/">Datenschutzerklärung</Link>.
          Klicken Sie auf „Zustimmen“, um Cookies zu akzeptieren und direkt
          unsere Website besuchen zu können.
        </P>
      </CookieConsent>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
