import PropTypes from "prop-types"
import React from "react"

import NavBar from "./navbar"

const Header = ({ pathname, siteTitle }) => <NavBar pathname={pathname} />

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
