import React, { useEffect } from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

import Hero from "../components/hero"

// content of https://www.off-the-zone.com/forms/2147778007/embed.js
const script = `
  <link
    href="//fonts.googleapis.com/css?family=Open+Sans:400,700"
    rel="stylesheet"
    type="text/css"
  >
    <link
      rel="stylesheet"
      media="all"
      href="https://kajabi-app-assets.kajabi-cdn.com/assets/form_embed-ecdf08b1749d79682c2ab0463956d0c0ab955b29591a56ed173594b870d4dfb7.css"
    />
    <script src="https://kajabi-app-assets.kajabi-cdn.com/assets/form_embed-fd7a5360fe56f0a332f934492d89f18c2202d65871804504adca6fd4b6e78ceb.js"></script>
    <div id="kajabi-form" class="kajabi-form--inline">
      <form
        data-parsley-validate="true"
        data-kjb-disable-on-submit="true"
        action="https://www.off-the-zone.com/forms/2147778007/form_submissions"
        accept-charset="UTF-8"
        method="post"
      >
        <input name="utf8" type="hidden" value="&#x2713;" />
        <input
          type="hidden"
          name="authenticity_token"
          value="bba5ZTe8Djr6+VbtdIf2CZdVerSP5ztLP9VJgHdju9bsXQFMOlboA1KxQ33wZ2VB5wOctGobJutxTNHfx+okjA=="
        />
        <style id="form-embed-style" type="text/css">      
          #kajabi-form .kajabi-form__btn {        color: #000000;        background-color: #f17915 !important;      }
                  #kajabi-form .kajabi-form__btn:not(.kajabi-form__btn--disabled):hover {        background-color: #974a09;      } 
                   <\/style>
              

        <div class="kajabi-form__content">
          <div class="kajabi-form__title">Join Our Free Event!</div>
          <div class="kajabi-form__subtitle"> </div>
          <fieldset>
            <div class="text-field kajabi-form__form-item">
              <input
                type="text"
                name="form_submission[name]"
                id="form_submission_name"
                value=""
                required="required"
                placeholder="Name"
              />
            </div>
            <div class="email-field kajabi-form__form-item">
              <input
                required="required"
                placeholder="Email"
                data-parsley-remote="true"
                data-parsley-remote-validator="kjb_email_validator"
                type="email"
                name="form_submission[email]"
                id="form_submission_email"
              />
            </div>
            <button
              class="kajabi-form__btn kajabi-form__btn--block-mobile"
              type="submit"
            >
              Ich bin dabei!
            </button>
          </fieldset>
        </div>
      </form>
    </div>
  </link>
  `

const BJJ = ({ location, data }) => {
  return (
    <Layout pathname={location.pathname}>
      <SEO
        title="Ladies only Event bei Team Laurien & Alex"
        description="Kostenloses Event für Frauen um Kampfsport auszuprobieren."
      />
      <Hero
        values={[
          {
            id: 1,
            img: data.hero1,
            imgAlt: "Yoga in München",
            headline: [
              "Ladies Only Event",
              "#joinourfight am 8. März ist ausgebucht?",
            ],
            text: [
              "Keine Sorge, wir organisieren ein weiteres Ladies only Event!",
              "Am 19. März von 16:00 - 17:30 für Neueinsteigerinnen ab 12 - 99 Jahren herzlich willkommen!",
            ],
          },
        ]}
        color="#ffffff"
        buttoncolor="#f17915"
        pathname={location.pathname}
        cta="Ich bin dabei!"
      />

      <div>
        <div dangerouslySetInnerHTML={{ __html: script }}></div>
      </div>
    </Layout>
  )
}
export const query = graphql`
  {
    hero1: file(relativePath: { eq: "ladies-only.jpeg" }) {
      childImageSharp {
        gatsbyImageData(quality: 100, placeholder: NONE, layout: FULL_WIDTH)
      }
    }
  }
`
export default BJJ
